
  
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  
  export default {
    props: {
      item: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      currency(data) {
        return currencyFormatter.format(data);
      },
    },
  };
  